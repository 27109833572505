code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
  color: #c51700;
  background-color: #f5f5f5;
}
.rounded {
  border-radius: 50%;
}

.discord-mention {
  padding: 1px;
  background-color: rgba(88, 101, 242, 0.3);
  color: #505cdc;
}

.discord-role {
  color: rgb(80, 92, 220);
  background: rgba(88, 101, 242, 0.15);
}

.developed-with-youtube > img {
  max-height: 13em;
  max-width: 100%;
}

.footer {
  background-color: #fafafa;
  padding: 3rem 1.5rem 6rem;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.spin-hover:hover .spin-me {
  animation-duration: 500ms;
}

.spin-me {
  animation: spin 1000ms infinite linear;
}
